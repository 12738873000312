<template>
  <section>
    <ModalPergunta @clicked-on-save="save()">
      <v-row tag="section" wrap>
        <!-- TÍTULO -->
        <v-col class="mt-2" cols="12" sm="12" md="6">
          <v-text-field
            class="white"
            hide-details="true"
            ref="titulo"
            label="Título"
            placeholder="Escreva o titulo da pergunta"
            required
            outlined
            dense
            v-model="pergunta.titulo"
            data-vv-as="titulo"
            persistent-hint
            :hint="errors.first('form-fe.titulo')"
            :error="errors.collect('form-fe.titulo').length ? true : false"
            v-validate="'required|min:3'"
            data-vv-scope="form-fe"
            data-vv-validate-on="change|blur"
            id="titulo"
            name="titulo"
          ></v-text-field>
        </v-col>
        <!-- TIPO -->
        <v-col class="mt-2" cols="12" sm="12" md="6">
          <v-select
            class="white"
            hide-details="true"
            ref="tipo"
            label="Tipo"
            placeholder="Selecione o tipo de pergunta"
            :items="SelectItensTipos"
            dense
            outlined
            v-model="pergunta.tipo"
            item-text="nome"
            item-value="tipo"
            data-vv-as="tipo"
            persistent-hint
            :hint="errors.first('form-fe.tipo')"
            :error="errors.collect('form-fe.tipo').length ? true : false"
            v-validate="'required|included:text,radio,checkbox'"
            data-vv-scope="form-fe"
            data-vv-validate-on="change|blur"
            @change="confirmTypeChange()"
            id="tipo"
            name="tipo"
          ></v-select>
        </v-col>
        <!-- DESCRIÇÃO -->
        <v-col cols="12" sm="12" md="12">
          <v-textarea
            class="white"
            hide-details="true"
            ref="descricao"
            label="Descrição"
            outlined
            dense
            v-model.trim="pergunta.descricao"
            data-vv-as="descrição"
            persistent-hint
            :hint="errors.first('form-fe.descricao')"
            :error="errors.collect('form-fe.descricao').length ? true : false"
            v-validate="'required|min:5'"
            data-vv-scope="form-fe"
            data-vv-validate-on="change|blur"
            id="descricao"
            name="descricao"
          ></v-textarea>
        </v-col>
        <!-- OBRIGATORIEDADE -->
        <v-col cols="12" sm="12" md="6">
          <v-switch
            class="mt-0"
            v-model="pergunta.obrigatoriedade"
            label="Essa pergunta é obrigatória?"
            data-vv-as="obrigatoriedade"
            persistent-hint
            :hint="errors.first('form-fe.obrigatoriedade')"
            :error="
              errors.collect('form-fe.obrigatoriedade').length ? true : false
            "
            v-validate="'required'"
            data-vv-scope="form-fe"
            data-vv-validate-on="change|blur"
            id="obrigatoriedade"
            name="obrigatoriedade"
          ></v-switch>
        </v-col>
        <!-- LISTA DE OPÇÕES -->
        <v-col
          cols="12"
          sm="12"
          md="12"
          v-if="
            pergunta.tipo == 'radio' || pergunta.tipo == 'checkbox'
              ? true
              : false
          "
        >
          <ListaOpcoesComponent
            :limitOfOptionsOn="pergunta.tipo == 'radio' ? false : true"
            defineScope="form-fe"
          />
        </v-col>
      </v-row>
    </ModalPergunta>

    <Dialog :showDialog="showDialog" :maxWidth="400">
      <template slot="content">
        <v-card-title class="text-h5 black--text">
          Perca de opções!
        </v-card-title>
        <v-card-text>
          A atual pergunta tem opções registradas, se continuar com o tipo
          <strong>Opção única</strong> selecionado perderá todas as suas opções.
        </v-card-text>
      </template>
      <template slot="actions">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="showDialog = false">
          Ok, entendi.
        </v-btn>
      </template>
    </Dialog>
  </section>
</template>

<script>
import ListaOpcoesComponent from "./components/ListaOpcoesComponent.vue";
import Dialog from "@/components/Dialog.vue";
import ModalPergunta from "./components/ModalPergunta.vue";

export default {
  name: "FormFE",
  inject: ["$validator"],
  components: {
    ListaOpcoesComponent,
    Dialog,
    ModalPergunta,
  },
  data: () => ({
    showDialog: false,
    SelectItensTipos: [
      {
        nome: "Texto Aberto",
        tipo: "text",
      },
      {
        nome: "Opção Única",
        tipo: "radio",
      },
      {
        nome: "Múltipla Escolha",
        tipo: "checkbox",
      },
    ],
  }),
  methods: {
    confirmTypeChange() {
      if (this.pergunta.tipo == "text" && this.pergunta.opcoes.length > 0) {
        this.showDialog = true;
      }
    },
    save() {
      this.$validator.validate("form-fe.*").then((result) => {
        if (result) {
          this.pergunta.categoria = "FE";

          if (!this.validateIfExistsOptions()) return;

          this.$store.dispatch("pcoModelos/setClickedOnSave", true);
          this.loadings.loadingModalPergunta = true;
        }
      });
    },
    /**
     * @description Valida de a pergunta é do tipo Radio ou checkbox e se contem opções, caso não tenha será retornado uma mensagem.
     *
     * @return this
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    validateIfExistsOptions() {
      if (
        (this.pergunta.tipo == "radio" &&
          this.pergunta.subcategoria !== "FAV") ||
        this.pergunta.tipo == "checkbox"
      ) {
        if (this.pergunta.opcoes.length > 0) {
          return true;
        } else {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Necessário ter opções registradas.",
            cor: "red",
          });

          return false;
        }
      }

      return true;
    },
  },
  computed: {
    pergunta: {
      get() {
        return this.$store.getters["pcoPerguntas/getPergunta"];
      },
    },
    dialogModalPergunta: {
      get() {
        return this.$store.getters["pcoModelos/getDialogModalPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setDialogModalPergunta", value);
      },
    },
    loadings: {
      get() {
        return this.$store.getters["pcoModelos/getLoadings"];
      },
    },
  },
};
</script>

<style scoped>
.flex-salvar-e-fechar {
  display: flex;
  justify-content: flex-end;
  margin-right: 172px;
}
</style>
